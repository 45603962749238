import { PublicProductListItem } from "types/product";
import ProductCardLayout from "../layout";

function MarketProductCard({ item }: { item: PublicProductListItem }) {
  const UiObject = {
    coverUrl: item.coverUrl,
    title: item.name,
    subtitle: item.categoryName,
    productLink: "",
    realPrice: item.rmbPrice,
    id: item.id,
    isSoldOut: item.isSoldOut,
    hasOuterUrl: item.hasOuterUrl,
    ourterUrl: item.outerUrl,
    bottomLeftNode:
      <a className="user" onClick={e => e.stopPropagation()} href={`/user/${item.userInfo.id}/shop`} target="_blank">
        <div className="avatar" style={{ backgroundImage: `url(${item.userInfo.avatarUrl})` }}>
        </div>
        <span className="overflow-hidden">{item.userInfo.username}</span>
      </a>
    ,
    bottomRightNode: <>
      {
        !!item.isPrivateInquiry && <div className="isPrivateInquiry">私聊询价</div>
      }
      {
        !item.isPrivateInquiry && <div className="real">
          <span className="sign">￥</span>
          <span>{item.rmbPrice && (item.rmbPrice / 100).toFixed(2)}</span>
          {
            item.categoryList[0].code === 'yuegao' && <span className="qi">起</span>
          }
        </div>
      }
    </>
  };

  return ProductCardLayout({ item: UiObject });
}

export default MarketProductCard;
