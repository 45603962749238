import UniCardLayout from "../../layout";
import PrizeIcons from "../../common/prize-icons";
import WorkIcons from "../../common/hot-icons";
import { translateCardCategoryText } from "utils/functions/common";
import MediaIconsWork from "../../common/media-icons/work";
import { ScoreTaskDetail, ScoreWorkListItem } from "types/contest";

import { Button } from "antd";
import { useState } from "react";
import ScoreModal from "./score-modal";
import { ScoreWorkCardStyle } from "./style";
import ScoreAction from "./score-action";

function ScoreWorkCard({ item, needRefresh, scoreTask, taskMediaCategory, isTop = false, isShowScore = true, clickToTop }: { item: ScoreWorkListItem, needRefresh: () => void, scoreTask: ScoreTaskDetail, taskMediaCategory: number, isTop?: boolean, isShowScore?: boolean, clickToTop?: (detail: ScoreWorkListItem) => void }) {
  const [openModal, setOpenModal] = useState(false);
  const scoreInfo = () => {
    if (item.averageScore) {
      return <ScoreWorkCardStyle>
        <div className="scoreInfo">
          <div className="item">当前排名：<span>{item.top}</span></div>
          <div className="item score">平均分：<span>{item.averageScore}</span></div>
        </div>
      </ScoreWorkCardStyle>
    }
    return <></>
  }
  const UiObject = {
    coverUrl: item.coverUrl,
    title: item.title,
    subtitle: (
      <span>
        {item.dictMap.mediaCategory} |{" "}
        {translateCardCategoryText(item.categoryList)}
      </span>
    ),
    view: item.viewCount,
    hot: item.hot,
    userName: item.userInfo?.username,
    avatarUrl: item.userInfo.avatarUrl,
    userLink: `/user/${item.userId}`,
    icons: WorkIcons({ item }),
    workLink: `/work/detail/${item.id}?scoreTaskId=${scoreTask.id}&taskMediaCategory=${taskMediaCategory}`,
    rightTopNode: PrizeIcons({ item }),
    rightBottomNode: MediaIconsWork({ item }),
    centerNode: <ScoreAction item={item} isTop={isTop} clickToTop={clickToTop} />,
    leftBottomNode: scoreInfo(),
    bottomNode: (
      isShowScore && <Button
        disabled={(scoreTask.isSubmit) ? true : false}
        style={{ width: "100%", marginTop: 8 }}
        onClick={() => setOpenModal(true)}
        type="primary"
        className="btn"
      >
        {item.averageScore ? '修改' : '打分'}
      </Button>
    ),
  };

  const closeAndRefresh = () => {
    setOpenModal(false)
    needRefresh()
  }

  return (<>
    {item && openModal && <ScoreModal workCoverUrl={item.coverUrl} workTitle={item.title} taskId={scoreTask.id} workId={item.id} openModal={openModal} handleOk={closeAndRefresh} handleCancel={closeAndRefresh} mediaCategory={taskMediaCategory}></ScoreModal>}
    {UniCardLayout({ item: UiObject })}
  </>)
}

export default ScoreWorkCard;
